import Head from 'next/head';
import React from 'react';
import type { ReactElement } from 'react';
import { Box, Button, Flex, Image, Link, Text } from '@chakra-ui/react';

import { getPageTitle } from '@/config';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { blueGradient } from '@/theme';

const DashboardPage = () => {
  return (
    <>
      <Head>
        <title>{getPageTitle('Dashboard')}</title>
      </Head>

      <Box bg="gray.610">
        <Flex
          h="100vh"
          w="100vw"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          bgImg="/bg-login.png"
          bgSize="100%"
          backgroundPosition="center bottom"
          backgroundRepeat="no-repeat"
        >
          <Flex flexDirection="column" alignItems="center">
            <Image
              src="/platflow-logo.svg"
              alt="Platflow Health"
              width="224px"
              height="52px"
            />
            <Text mt="1.5rem" fontSize="xl" fontWeight="normal">
              Modern ai for skilled nursing
            </Text>
            <Link href="/api/auth/login?returnTo=/users" mt={20}>
              <Button id="login-button" width="240px">
                Log in
              </Button>
            </Link>

            <Text fontSize="lg" mt={6}>
              No account?{' '}
              <Link
                bgGradient={blueGradient}
                backgroundClip="text"
                textDecoration="underline"
                href="mailto:support@healthtap.ai"
                _hover={{
                  textDecoration: 'none',
                }}
              >
                Learn more here
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

DashboardPage.getLayout = function getLayout(page: ReactElement) {
  return <UserProvider>{page}</UserProvider>;
};

export default DashboardPage;
